import * as React from "react"
import { Outlet } from "react-router-dom"
import { FaLinkedin, FaSquareFacebook } from "react-icons/fa6"
import { Button } from "@allied/react-web/Button"
import { Footer } from "@allied/react-web/Footer"
import { Modal } from "@allied/react-web/Modal"
import { SiteContext } from "../components/Site"
import { ToastContainer } from "../components/Toast"

export default function Guest(): React.JSX.Element {
  const [modalSite, setModalSite] = React.useState({
    opened: false,
  })
  const [selectedSite, setSelectedSite] = React.useState(0)
  const { site, otherSites } = React.useContext(SiteContext)

  const handleChangeSite = () => {
    const site = otherSites[selectedSite]
    if (!site || site.hosts.size === 0) {
      return
    }

    const url = Array.from(site.hosts.values())[0]
    window.open(`http://${url}`, "_blank")
  }

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen">
        <Outlet />
      </div>
      <Footer.AlliedDepot
        backgroundImage="/image/acdsuae-footer.webp"
        logoImage="/image/allied-logo.webp"
        logoAlt="Allied"
        copyrightLabel="Copyright &copy; 2023 Allied Ondock Container Services LLC"
        mediaItems={[
          {
            to: "https://www.facebook.com/AlliedContainerGroup",
            target: "_blank",
            hideCrawl: true,
            content: <FaSquareFacebook className="w-6 h-6 text-white" aria-hidden="true" />,
            label: "Facebook",
          },
          {
            to: "https://www.linkedin.com/company/allied-container-group/",
            target: "_blank",
            hideCrawl: true,
            content: <FaLinkedin className="w-6 h-6 text-white" aria-hidden="true" />,
            label: "Linkedin",
          }
        ]}
        rightChildren={
          <>
            <div className="flex flex-row sm:!flex-col justify-start items-center sm:!items-start gap-2">
              <p className="w-20 sm:!w-full text-sm text-zinc-400">You are in:</p>
              <Button variant="secondary" appendClassNames="w-64 sm:!w-full"
                onClick={() => {
                  setModalSite((prevState) => {
                    return {
                      ...prevState,
                      opened: true
                    }
                  })
                }}>
                <span className="flex flex-row gap-2 justify-center items-center">
                  <img src={site.icon} alt="site icon" className="w-4 h-4" />
                  <span className="font-bold">{site.name}</span>
                </span>
              </Button>

              <Modal
                portalId="overlay-root"
                size="xs"
                opened={modalSite.opened}
                onClose={() => {
                  setModalSite((prevState) => {
                    return {
                      ...prevState,
                      opened: false
                    }
                  })
                }}
                lostable
                closeable>
                {{
                  content: (
                    <>
                      <div className="flex flex-col gap-3">
                        <h1 className="text-3xl md:!text-4xl text-center font-semibold">
                          Country
                        </h1>
                        {
                          otherSites.length > 0 &&
                          <>
                            <div className="flex flex-col justify-center items-center gap-2">
                              {
                                otherSites.map((site, i: number) => {
                                  return (
                                    <button key={`site-item-${i}`} className={
                                      "w-64 sm:!w-full p-2 border-1 rounded-3xl " +
                                      (i === selectedSite ? "border-acds-primary" : "border-transparent hover:border-acds-primary")
                                    }
                                      onClick={() => { setSelectedSite(i) }}>
                                      <span className="flex flex-row gap-2 justify-center items-center">
                                        <img src={site.icon} alt="site icon" className="w-4 h-4" />
                                        <span>{site.name}</span>
                                      </span>
                                    </button>
                                  )
                                })
                              }
                            </div>
                            <Button variant="primary" onClick={handleChangeSite}>
                              Continue
                            </Button>
                          </>
                        }
                      </div>
                    </>
                  )
                }}
              </Modal>
            </div>
          </>
        }
      />
    </>
  )
}
